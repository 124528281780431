import {
  PathSpec,
  getPathGenerator,
  getPathGeneratorWithParams,
} from './getPathGenerator';

type ValidMarketingPathSpec =
  | PathSpec<'/'>
  | PathSpec<'/about'>
  | PathSpec<'/event'>
  | PathSpec<'/community'>
  | PathSpec<'/community-rules'>
  | PathSpec<'/faq'>
  | PathSpec<'/contact'>
  | PathSpec<'/privacy-policy'>
  | PathSpec<'/terms-of-use'>;

export const linkToMarketing = getPathGenerator<ValidMarketingPathSpec>('');

type ValidMarketingPathSpecWithParams = PathSpec<'/event/:id', {id: string}>;

export const linkToMarketingWithParams =
  getPathGeneratorWithParams<ValidMarketingPathSpecWithParams>('');

export function linkToAllizInstagram(): string {
  return 'https://www.instagram.com/allizcareer';
}

export function linkToAllizLinkedin(): string {
  return 'https://www.linkedin.com/company/allizcareer/';
}

export function linkToAllizX(): string {
  return 'https://twitter.com/allizcareer';
}

export function linkToAllizNote(): string {
  return 'https://note.com/allizcareer/';
}
