import PolicyLink from './PolicyLink';
import brand from '../../../../__generated__/intl/strings/brand';
import copyright from '../../../../__generated__/intl/strings/copyright';
import poweredBy from '../../../../__generated__/intl/strings/footer/poweredBy';
import privacyPolicyPageTitle from '../../../../__generated__/intl/strings/pages/privacyPolicy/privacyPolicyPageTitle';
import termsOfUsePageTitle from '../../../../__generated__/intl/strings/pages/termsOfUse/termsOfUsePageTitle';
import {linkToMarketing} from '../../../url/linkToMarketing';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import useMediaQuery from '../../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../../design_system/hooks/useMediaQueryGetter';
import Separator from '../../design_system/icon/Separator';
import {ContentColor} from '../../design_system/theme/useContentPalette';
import Typography, {Size} from '../../design_system/typography/Typography';
import Flex from '../flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {}

export default function PolicyFooter(_: Props): JSX.Element {
  const {palettes, spacing} = useAppTheme();

  const wrappedMediaQuery = useMediaQuery(MediaQuery.SmallAndMedium);

  return (
    <Flex
      sx={css({
        justifyContent: 'center',
        backgroundColor: palettes.background.neutralContrast.default,
        position: 'relative',
        zIndex: 100,
      })}
    >
      <div
        css={css({
          alignItems: 'center',
          display: 'grid',
          paddingTop: spacing.x16,
          paddingBottom: spacing.x16,
          gridTemplateColumns: 'repeat(7, auto)',
          columnGap: spacing.x20,
          rowGap: spacing.x8,

          [wrappedMediaQuery]: {
            gridTemplateColumns: 'auto auto',
            gridTemplateRows: 'repeat(5, 1fr)',
          },
        })}
        key="grid-container"
      >
        <Flex
          sx={css({
            justifyContent: 'center',
            [wrappedMediaQuery]: {
              gridColumn: '1 / span 2',
            },
          })}
        >
          <Typography
            color={ContentColor.PRIMARY_CONTRAST}
            key="column-1"
            size={Size.XS}
            variant="div"
            weight={700}
          >
            {poweredBy()}
          </Typography>
        </Flex>

        <Flex
          sx={css({
            justifyContent: 'center',

            [wrappedMediaQuery]: {
              gridColumn: '1 / span 2',
              gridRow: '2 / span 1',
            },
          })}
        >
          <Separator key="column-2" />
        </Flex>

        <PolicyLink
          key="column-3"
          style={css({
            [wrappedMediaQuery]: {
              gridColumn: '1 / spaan 1',
              gridRow: '3 / span 1',
            },
          })}
          to={linkToMarketing('/privacy-policy')}
        >
          {privacyPolicyPageTitle()}
        </PolicyLink>

        <div
          css={css({
            [wrappedMediaQuery]: {
              display: 'none',
            },
          })}
        >
          <Separator key="column-4" />
        </div>

        <PolicyLink
          key="column-5"
          style={css({
            [wrappedMediaQuery]: {
              gridColumn: '2 / span 1',
              gridRow: '3 / span 1',
            },
          })}
          to={linkToMarketing('/terms-of-use')}
        >
          {termsOfUsePageTitle()}
        </PolicyLink>

        <Flex
          sx={css({
            justifyContent: 'center',

            [wrappedMediaQuery]: {
              gridColumn: '1 / span 2',
              gridRow: '4 / span 1',
            },
          })}
        >
          <Separator key="column-6" />
        </Flex>

        <Typography
          color={ContentColor.PRIMARY_CONTRAST}
          key="column-7"
          rootStyle={css({
            [wrappedMediaQuery]: {
              gridColumn: '1 / span 2',
              gridRow: '5 / span 1',
            },
          })}
          size={Size.XS}
          variant="div"
        >
          {copyright(brand())}
        </Typography>
      </div>
    </Flex>
  );
}
