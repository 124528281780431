import React from 'react';
import {CSSStyles} from '../../../types/CSSStyles';
import useAppTheme from '../hooks/useAppTheme';
import {css} from '@emotion/react';

export default function Separator(props: {style?: CSSStyles}): JSX.Element {
  const {palettes} = useAppTheme();
  return (
    <div
      css={[
        css({
          backgroundColor: palettes.background.accent.default,
          borderRadius: '50%',
          height: 5,
          width: 5,
        }),
      ]}
    />
  );
}
