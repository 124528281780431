import Brand, {BrandPlacement} from './Brand';
import closeTopNavMenu from '../../../../__generated__/intl/strings/nav/closeTopNavMenu';
import topNavMenu from '../../../../__generated__/intl/strings/nav/topNavMenu';
import IconButton from '../../design_system/Button/IconButton';
import {ButtonColor, ButtonSize} from '../../design_system/Button/options';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import useMediaQuery from '../../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../../design_system/hooks/useMediaQueryGetter';
import Close from '../../design_system/icon/Close';
import Menu from '../../design_system/icon/Menu';
import HorizontalProgress from '../../design_system/progress/HorizontalProgress';
import white from '../../design_system/theme/colors/white';
import {WRAPPER_Z_INDEX} from '../../Image/PanAndZoomImageCanvas';
import Flex from '../flex/Flex';
import Gap from '../flex/Gap';
import useWindowScrollDiabler from '../useWindowScrollDisabler';

import {css} from '@emotion/react';
import React, {useCallback, useEffect, useId, useState} from 'react';
import {useNavigation} from 'react-router-dom';

export interface NavItem {
  readonly to: string;
}

export type MobileNavItemLayout = 'viewer-at-top' | 'viewer-at-bottom';

export interface Props {
  readonly extraNav: React.ReactElement<NavItem>[];
  readonly mobileNavItemLayout: MobileNavItemLayout;
  readonly viewerNavControls: React.ReactNode;
  readonly onMenuToggle?: (isOpen: boolean) => void;
  readonly placement?: BrandPlacement;
}

export default function TopNav(props: Props): JSX.Element {
  const {
    extraNav,
    mobileNavItemLayout,
    onMenuToggle,
    placement,
    viewerNavControls,
  } = props;
  const id = useId();
  const {palettes, sizes, spacing} = useAppTheme();
  const collapsedMQ = useMediaQuery(MediaQuery.SmallAndMedium);
  const smallScreenMenuMQ = useMediaQuery(MediaQuery.SmallScreen);
  const mediumScreenMenuMQ = useMediaQuery(MediaQuery.MediumScreen);
  const [openMenu, setOpenMenu] = useState(false);
  const windowScrollDisabler = useWindowScrollDiabler();
  const toggleMenu = useCallback(
    (isOpen: boolean) => {
      setOpenMenu(isOpen);
      onMenuToggle?.(isOpen);
      if (isOpen) {
        windowScrollDisabler.addKey(id);
      } else {
        windowScrollDisabler.removeKey(id);
      }
      return () => {
        windowScrollDisabler.removeKey(id);
      };
    },
    [id, onMenuToggle, windowScrollDisabler],
  );
  const navigation = useNavigation();
  useEffect(() => {
    if (navigation.state === 'loading') {
      toggleMenu(false);
    }
  }, [navigation.state, toggleMenu]);

  const loadingIndicator = (
    <div
      css={css({
        position: 'fixed',
        width: '100vw',
        height: 5,
        top: sizes.l.topNavHeight,

        [useMediaQuery(MediaQuery.SmallScreen)]: {
          top: sizes.sm.topNavHeight,
        },
        [useMediaQuery(MediaQuery.MediumScreen)]: {
          top: sizes.md.topNavHeight,
        },
      })}
    >
      <HorizontalProgress />
    </div>
  );

  return (
    <>
      {openMenu && (
        <div
          aria-label={closeTopNavMenu()}
          css={css({
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            height: '100vh',
            left: 0,
            opacity: openMenu ? 100 : 0,
            pointerEvents: openMenu ? 'inherit' : 'none',
            cursor: 'pointer',
            position: 'fixed',
            top: 0,
            transition: 'opacity 0.3s ease-in-out',
            width: '100vw',
            display: 'none',
            zIndex: WRAPPER_Z_INDEX * 10,

            [collapsedMQ]: {
              display: 'block',
            },
          })}
          data-testid="nav-backdrop"
          key="backdrop"
          role={openMenu ? 'button' : 'none'}
          onClick={() => {
            toggleMenu(false);
          }}
        />
      )}
      <div
        css={css({
          backgroundColor: white.solid,
          boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.03)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: WRAPPER_Z_INDEX * 10,
        })}
      >
        <Flex
          sx={css({
            boxSizing: 'border-box',
            height: sizes.l.topNavHeight,
            paddingLeft: sizes.l.pageSidePadding,
            paddingRight: sizes.l.pageSidePadding,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: sizes.maxWidth,

            [useMediaQuery(MediaQuery.SmallScreen)]: {
              height: sizes.sm.topNavHeight,
              paddingLeft: sizes.sm.pageSidePadding,
              paddingRight: sizes.sm.pageSidePadding,
            },
            [useMediaQuery(MediaQuery.MediumScreen)]: {
              height: sizes.md.topNavHeight,
              paddingLeft: sizes.md.pageSidePadding,
              paddingRight: sizes.md.pageSidePadding,
            },
          })}
        >
          <Brand
            placement={placement}
            rootStyle={css({
              height: 48,

              [useMediaQuery(MediaQuery.SmallAndMedium)]: {
                height: 36,
              },
            })}
          />
          <Gap />
          <Flex
            data-testid="extra-nav"
            sx={[
              css({
                alignItems: 'center',
                gap: spacing.x32,

                [collapsedMQ]: {
                  display: 'none',
                },
              }),
            ]}
          >
            {extraNav}
          </Flex>
          <div
            css={css({
              [collapsedMQ]: {
                display: 'none',
              },
            })}
            data-testid="viewer-nav-controls"
          >
            {viewerNavControls}
          </div>
          <div
            css={css({
              display: 'none',

              [collapsedMQ]: {
                display: 'block',
              },
            })}
          >
            {/* Menu */}
            <IconButton
              color={ButtonColor.TRANSPARENT}
              data-testid="top-nav-menu-button"
              icon={
                <Menu
                  height={20}
                  width={20}
                />
              }
              label={topNavMenu()}
              size={ButtonSize.SMALL}
              onPress={() => {
                toggleMenu(true);
              }}
            />
            <Flex
              direction="column"
              sx={[
                css({
                  alignItems: 'flex-start',
                  justifyContent: 'stretch',
                  height: '100vh',
                  overflowY: 'auto',
                  backgroundColor: palettes.background.white.default,
                  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.13)',
                  boxSizing: 'border-box',
                  paddingLeft: spacing.x20,
                  paddingRight: spacing.x20,
                  paddingTop: spacing.x12,
                  paddingBottom: 120,
                  position: 'fixed',
                  right: 0,
                  transform: 'translateX(100%)',
                  transition: 'transform 0.1s ease-in-out',

                  [mediumScreenMenuMQ]: {
                    top: 0,
                    width: 300,
                  },
                  [smallScreenMenuMQ]: {
                    top: 0,
                    width: '100%',
                  },
                }),
                openMenu &&
                  css({
                    transform: 'translateX(0)',
                  }),
              ]}
            >
              <Flex sx={css({justifyContent: 'flex-end', width: '100%'})}>
                <IconButton
                  color={ButtonColor.TRANSPARENT}
                  data-testid="close-nav-menu-button"
                  icon={
                    <Close
                      height={20}
                      width={20}
                    />
                  }
                  label={topNavMenu()}
                  size={ButtonSize.SMALL}
                  onPress={() => {
                    toggleMenu(false);
                  }}
                />
              </Flex>

              {mobileNavItemLayout === 'viewer-at-top' && (
                <div
                  css={css({width: '100%', marginBottom: spacing.x24})}
                  data-testid="viewer-nav-controls-in-menu"
                >
                  {viewerNavControls}
                </div>
              )}

              {extraNav}

              {mobileNavItemLayout === 'viewer-at-bottom' && (
                <div
                  css={css({width: '100%'})}
                  data-testid="viewer-nav-controls-in-menu"
                >
                  {viewerNavControls}
                </div>
              )}
            </Flex>
          </div>
        </Flex>
        {navigation.state !== 'idle' && loadingIndicator}
      </div>
    </>
  );
}
