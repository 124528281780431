import {
  ViewerNavControls_FeatureFlagsFragment,
  ViewerNavControlsFragment,
} from './__generated__/ViewerNavControls.graphql';
import NavAvatarButton from './NavAvatarButton';
import login from '../../../../__generated__/intl/strings/login';
import signup from '../../../../__generated__/intl/strings/nav/signup';
import {linkToApp} from '../../../url/linkToApp';
import Button from '../../design_system/Button';
import {ButtonColor, ButtonSize} from '../../design_system/Button/options';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import useMediaQueryGetter, {
  MediaQuery,
} from '../../design_system/hooks/useMediaQueryGetter';
import Flex from '../flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly data: Maybe<ViewerNavControlsFragment>;
  readonly featureFlags: Maybe<ViewerNavControls_FeatureFlagsFragment>;
}

export default function ViewerNavControls(props: Props): JSX.Element | null {
  const {data, featureFlags} = props;
  const {spacing} = useAppTheme();
  const getMediaQuery = useMediaQueryGetter();

  let content;
  let hasContent = false;
  if (data != null) {
    content = <NavAvatarButton user={data} />;
    hasContent = true;
  } else {
    content = (
      <>
        {featureFlags?.login && (
          <Button
            color={ButtonColor.SECONDARY}
            label={login()}
            size={ButtonSize.LARGE}
            sx={css({width: '100%'})}
            to={linkToApp('/login')}
          />
        )}
        {featureFlags?.signup && (
          <Button
            color={ButtonColor.ACCENT}
            label={signup()}
            size={ButtonSize.LARGE}
            sx={css({width: '100%'})}
            to={linkToApp('/signup')}
          />
        )}
      </>
    );
    hasContent = Boolean(featureFlags?.login || featureFlags?.signup);
  }

  if (!hasContent) {
    return null;
  }

  return (
    <Flex
      sx={css({
        gap: spacing.x16,
        marginLeft: spacing.x60,

        [getMediaQuery(MediaQuery.SmallAndMedium)]: {
          alignItems: 'start',
          flexDirection: 'column',
          margin: 'auto',
          marginTop: spacing.x32,
        },
      })}
    >
      {content}
    </Flex>
  );
}
