import {
  GlobalNavigation_FeatureFlagsFragment,
  GlobalNavigation_UserFragment,
} from './__generated__/GlobalNavigation.graphql';
import {BrandPlacement} from './Nav/Brand';
import TopNav from './Nav/TopNav';
import ViewerNavControls from './Nav/ViewerNavControls';
import {CSSStyles} from '../../types/CSSStyles';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  featureFlags: Maybe<GlobalNavigation_FeatureFlagsFragment>;
  user: Maybe<GlobalNavigation_UserFragment>;
  readonly children?: React.ReactNode;
  readonly extraNav?: JSX.Element[];
  readonly footer?: React.ReactNode;
  readonly placement?: BrandPlacement;
  readonly showViewerNavControls?: boolean;
  readonly sx?: CSSStyles;
  readonly topBanner?: React.ReactNode;
}

export default function GlobalNavigationLayout(props: Props) {
  const {
    children,
    extraNav = [],
    featureFlags,
    footer,
    placement,
    showViewerNavControls = true,
    sx: rootStyle,
    topBanner,
    user,
  } = props;
  const {sizes} = useAppTheme();

  const topBannerPadding = css({
    paddingTop: sizes.l.topNavHeight,
    [useMediaQuery(MediaQuery.SmallScreen)]: {
      paddingTop: sizes.sm.topNavHeight,
    },
    [useMediaQuery(MediaQuery.MediumScreen)]: {
      paddingTop: sizes.md.topNavHeight,
    },
  });

  return (
    <div css={[rootStyle, topBannerPadding]}>
      <TopNav
        extraNav={extraNav}
        mobileNavItemLayout={
          user == null ? 'viewer-at-bottom' : 'viewer-at-top'
        }
        placement={placement}
        viewerNavControls={
          showViewerNavControls && (
            <ViewerNavControls
              data={user}
              featureFlags={featureFlags}
              key="viewer-nav-controls"
            />
          )
        }
      />
      {topBanner}
      <div
        css={[
          css({
            maxWidth: sizes.maxWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
          }),
        ]}
      >
        {children}
      </div>
      {footer}
    </div>
  );
}
